import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import Journal from '../views/Journal.vue';
import Plan from '../views/Plan.vue';
import Fichier from '../views/Fichier.vue';
import Bilan from '../views/Bilan.vue';
import CalculateurSalaire from '../views/CalculateurSalaire.vue';
import ExerciceComptable from '../views/ExerciceComptable.vue';
import FicheSalaire from '../views/FicheSalaire.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/calculateur',
    name: 'Calculateur Salaire',
    component: CalculateurSalaire,
  },
  {
    path: '/comptabilite/:comptabilite/fiche-salaire',
    name: 'fiche salaire',
    component: FicheSalaire,
  },
  {
    path: '/comptabilite/:comptabilite/exercicecomptable/:exercice/journal',
    name: 'journal',
    component: Journal,
  },
  {
    path: '/comptabilite/:comptabilite/exercicecomptable/:exercice/plan',
    name: 'plan comptable',
    component: Plan,
  },
  {
    path: '/comptabilite/:comptabilite/exercicecomptable/:exercice/fichier',
    name: 'fichier',
    component: Fichier,
  },
  {
    path: '/comptabilite/:comptabilite/exercicecomptable/:exercice',
    name: 'bilan',
    component: Bilan,
  },
  {
    path: '/comptabilite/:comptabilite',
    name: 'comptabilite',
    component: ExerciceComptable,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
