<template>
  <div class="journal">
    <h1 class="title">Journal</h1>
    <JournalList />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
// eslint-disable-next-line import/no-unresolved, import/extensions
import JournalList from '@/components/JournalList.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'comptaJournal',
  components: {
    JournalList,
  },
});
</script>

<style scoped>

.title {
  margin: auto;
  text-align: center;
  font-size: 22px;
}

</style>
