<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import type { Ref } from 'vue';
import axios, { AxiosResponse } from 'axios';
// eslint-disable-next-line import/no-unresolved, import/extensions
import { VaFile } from 'vuestic-ui/web-components';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Cotisation from '@/types/Cotisation';
// eslint-disable-next-line import/no-unresolved, import/extensions
import { roundTwoDecimal } from '@/components/Utils';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Canton from '@/types/Canton';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Employe from '@/types/Employe';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Entreprise from '@/types/Entreprise';

interface FicheSalaireRow {
  libelle: string,
  input: number,
  amount?: number,
  rate?: number,
}

interface MoisSalaire {
  mois: number,
  annee: number,
  verse: Date,
}

const monthOptions = Array.from({ length: 12 }, (_, i) => i + 1);
const yearOptions = Array.from({ length: 4 }, (_, i) => i + 2023);

const entreprise: Ref<Entreprise> = ref({
  id: 0,
  nom: 'ABC',
  adresse: 'Rue du schtroumpf 2',
  npa: '1234',
  ville: 'Schtroumpf',
});

const employe: Ref<Employe> = ref({
  id: 0,
  genre: 'M',
  nom: 'Employe',
  prenom: 'Test',
  adresse: 'Rue du schtroumpf 2',
  npa: '1234',
  ville: 'Schtroumpf',
  avs: '756.2134.21235.25',
  dateNaissance: new Date(),
  age: 30,
  banque: 'CH540026826810423540U',
});

const ficheSalaireDate: Ref<MoisSalaire> = ref({
  mois: 1,
  annee: 2024,
  date: new Date(),
});

const toggleOptions = [
  { value: 'fixed', icon: 'attach_money' },
  { value: 'rate', icon: 'percent' },
];
const toggleRetenueValue: Ref<string> = ref('fixed');
const toggleDiversValue: Ref<string> = ref('fixed');

const salaireBrut: Ref<number> = ref(0);

const ligneRetenu:Ref<FicheSalaireRow> = ref({
  libelle: '',
  input: 0,
});
const ligneDivers:Ref<FicheSalaireRow> = ref({
  libelle: '',
  input: 0,
});

const CantonValue: Ref<Canton|undefined> = ref();

const retenues:Ref<FicheSalaireRow[]> = ref([]);
const divers:Ref<FicheSalaireRow[]> = ref([]);
const cotisationSalariale: Ref<Cotisation[]> = ref([]);

const salaireAnnuel = computed(
  () => salaireBrut.value * 12,
);

const salaireNet = computed(
  () => salaireBrut.value - (retenues.value.reduce((partialSum, a) => partialSum + Number('amount' in a ? a.amount : (a.rate * salaireBrut.value) / 100), 0)),
);

const totalSalaire = computed(
  () => salaireNet.value + (divers.value.reduce((partialSum, a) => partialSum + Number('amount' in a ? a.amount : (a.rate * salaireBrut.value) / 100), 0)),
);

// put everything below in specific class
function filterCotisation(c: Cotisation): boolean {
  if (c.limiteBasseAnnuel !== null) {
    if (salaireAnnuel.value === undefined || c.limiteBasseAnnuel > salaireAnnuel.value) {
      return false;
    }
  }
  if (c.limiteHauteAnnuel !== null) {
    if (salaireAnnuel.value === undefined || c.limiteHauteAnnuel < salaireAnnuel.value) {
      return false;
    }
  }

  if (c.ageMin !== null) {
    if (employe.value.age === undefined || c.ageMin > employe.value.age) {
      return false;
    }
  }
  if (c.ageMax !== null) {
    if (employe.value.age === undefined || c.ageMax < employe.value.age) {
      return false;
    }
  }

  return true;
}
const cotisationSalarialeActive = computed(
  () => cotisationSalariale.value.filter(filterCotisation),
);

function ajouterCotisations():void {
  cotisationSalarialeActive.value.forEach(
    (cs) => {
      retenues.value.push({
        libelle: cs.libelle,
        input: cs.taux - cs.tauxEmployeur,
        rate: cs.taux - cs.tauxEmployeur,
      });
    },
  );
}

async function getCotisations() {
  console.log('retrieve Cotisations');
  let arr = [];
  try {
    // Fetch federal contributions
    const responseFederale = await axios.get('https://api2.helpcomptabilite.ch/cotisationsalariale/federale/');
    const federalContributions: Cotisation[] = responseFederale.data;

    arr = federalContributions;

    // Fetch cantonal contributions if a CantonValue is provided
    if (CantonValue.value !== undefined) {
      const responseCantonale = await axios.get(`https://api2.helpcomptabilite.ch/cotisationsalariale/${CantonValue.value.id}`);
      const cantonalContributions: Cotisation[] = responseCantonale.data;
      cotisationSalariale.value = arr.concat(cantonalContributions);
    } else {
      cotisationSalariale.value = arr;
    }

    cotisationSalariale.value = arr;
  } catch (error) {
    // Handle errors
    console.error('Error fetching data:', error);
  }
}

function readFile(file:any) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = reject;
    fr.readAsDataURL(file);
  });
}

async function toPostBody() {
  const en = entreprise.value;
  let base64 = '';
  if (en.logoFile !== undefined) {
    const fileData = await readFile(en.logoFile[0] as File);
    base64 = fileData as string;
  }
  en.logo = base64;
  return JSON.stringify({
    fiche: ficheSalaireDate.value,
    entreprise: en,
    employe,
    salaire: {
      brut: salaireBrut,
      net: salaireNet.value,
      total: totalSalaire.value,
    },
    retenues: retenues.value,
    divers: divers.value,
  });
}

async function generatePDF() {
  try {
    const postData = await toPostBody();
    console.log(postData);
    const pdf = await axios.post('https://localhost:4430/fichesalaire', postData);
    console.log(pdf);
  } catch (error) {
    console.error('Error generating pdf:', error);
  }
}

onMounted(() => {
  getCotisations();
});

</script>

<template>
  <div class="plan">
    <h1 class="title">Fiche Salaire</h1>
    <h2>options</h2>
    <VaButtonGroup>
      <VaButton>Sauvegarder</VaButton>
      <VaButton>Charger</VaButton>
      <VaButton @click="() => generatePDF()">PDF</VaButton>
    </VaButtonGroup>
    <h2>Entreprise</h2>
    <VaInput
      v-model="entreprise.nom"
      placeholder="Entreprise"
    />
    <VaInput
      v-model="entreprise.adresse"
      placeholder="Adresse"
    />
    <VaInput
      v-model="entreprise.npa"
      placeholder="NPA"
    />
    <VaInput
      v-model="entreprise.ville"
      placeholder="Ville"
    />
    <VaFileUpload
      v-model="entreprise.logoFile"
      dropzone
      type="gallery"
      file-types="image/*"
    />
    <h2>Employe</h2>
    <VaButtonToggle
      v-model="employe.genre"
      :options="[
        { label: 'M', value: 'M' },
        { label: 'F', value: 'F' },
        { label: 'autre', value: undefined },
      ]"
    />
    <VaInput
      v-model="employe.nom"
      placeholder="Nom"
    />
    <VaInput
      v-model="employe.prenom"
      placeholder="Prénom"
    />
    <VaInput
      v-model="employe.adresse"
      placeholder="Adresse"
    />
    <VaInput
      v-model="employe.npa"
      placeholder="NPA"
    />
    <VaInput
      v-model="employe.ville"
      placeholder="Ville"
    />
    <VaInput
      v-model="employe.avs"
      placeholder="N° AVS"
    />
    <VaInput
      v-model="employe.banque"
      placeholder="Compte bancaire"
    />
    <VaSelect
      v-model="ficheSalaireDate.mois"
      :options="monthOptions"
      placeholder="Choisir un mois"
    />
    <VaSelect
      v-model="ficheSalaireDate.annee"
      :options="yearOptions"
      placeholder="Choisir une année"
    />
    <h2>Lignes</h2>
    <div class="row">
      <h3>Salaire</h3>
      <VaInput
        v-model="salaireBrut"
        placeholder="Montant"
        type="number"
      />
      <VaButton
        @click="() => ajouterCotisations()">
        Cotisation Automatique
      </VaButton>
    </div>
    <div class="row">
      <h3>Retenues</h3>
      <VaInput
        v-model="ligneRetenu.libelle"
        placeholder="Libelle"
      />
      <VaInput
        v-model="ligneRetenu.input"
        placeholder="Montant"
        :pattern="toggleRetenueValue == 'fixed' ? '\d+.?\d{0,2}' : '\d{1,2}.?\d{0,2}'"
      >
        <template #append>
          <VaButtonToggle
            v-model="toggleRetenueValue"
            :options="toggleOptions"
          />
        </template>
      </VaInput>

      <VaButton
        @click="() => {
          if (toggleRetenueValue == 'fixed') {
            retenues.push({
              libelle: ligneRetenu.libelle,
              input: Number(ligneRetenu.input),
              amount: Number(ligneRetenu.input),
            });
          } else {
            retenues.push({
              libelle: ligneRetenu.libelle,
              input: Number(ligneRetenu.input),
              rate: Number(ligneRetenu.input),
            });
          }

          ligneRetenu.libelle = '';
          ligneRetenu.input = 0;
        }">
        Ajouter
      </VaButton>
    </div>
    <div class="row">
      <h3>Divers</h3>
      <VaInput
        v-model="ligneDivers.libelle"
        placeholder="Libelle"
      />
      <VaInput
        v-model="ligneDivers.input"
        placeholder="Montant"
        type="number"
      >
        <template #append>
          <VaButtonToggle
            v-model="toggleDiversValue"
            :options="toggleOptions"
          />
        </template>
      </VaInput>
      <VaButton
        @click="() => {
          if (toggleDiversValue == 'fixed') {
            divers.push({
              libelle: ligneDivers.libelle,
              input: Number(ligneDivers.input),
              amount: Number(ligneDivers.input),
            });
          } else {
            divers.push({
              libelle: ligneDivers.libelle,
              input: Number(ligneDivers.input),
              rate: Number(ligneDivers.input),
            });
          }
          ligneDivers.libelle = '';
          ligneDivers.input = 0;
        }">
        Ajouter
      </VaButton>
    </div>
    <table class="va-table">
      <thead>
        <tr>
          <th>Libellé</th>
          <th>Montant</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="3">
            <b>Revenus</b>
          </td>
        </tr>
        <tr>
          <td>
            <b>Salaire Brut</b>
          </td>
          <td>
            <b>{{salaireBrut}}</b>
          </td>
          <td />
        </tr>
        <tr>
          <td colspan="3">
            <b>Retenues</b>
          </td>
        </tr>
        <tr
          v-for="(revenu, index) in retenues"
          :key="index"
        >
          <td>
            {{revenu.libelle}}
            <span v-if="'rate' in revenu">
              ({{ revenu.rate }} %)
            </span>
          </td>
          <td v-if="'amount' in revenu">
            {{revenu.amount}} CHF
          </td>
          <td v-if="'rate' in revenu">
            {{roundTwoDecimal(revenu.rate * salaireBrut / 100)}} CHF
          </td>
          <td>
            <VaButtonGroup>
              <VaButton>
                Editer (TODO)
              </VaButton>
              <VaButton
                @click="() => retenues.splice(index, 1)">
                Enlever
              </VaButton>
            </VaButtonGroup>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <b>Salaire net</b>
          </td>
        </tr>
        <tr>
          <td>Salaire net</td>
          <td>
            {{ salaireNet }}
          </td>
          <td />
        </tr>
        <tr>
          <td colspan="3">
            <b>Divers</b>
          </td>
        </tr>
        <tr
          v-for="(diver, index) in divers"
          :key="index"
        >
          <td>
            {{diver.libelle}}
            <span v-if="'rate' in diver">
              ({{ diver.rate }} %)
            </span>
          </td>
          <td v-if="'amount' in diver">
            {{diver.amount}} CHF
          </td>
          <td v-if="'rate' in diver">
            {{roundTwoDecimal(diver.rate * salaireBrut / 100)}} CHF
          </td>
          <td>
            <VaButtonGroup>
              <VaButton>
                Editer (TODO)
              </VaButton>
              <VaButton
                @click="() => divers.splice(index, 1)">
                Enlever
              </VaButton>
            </VaButtonGroup>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <b>Total Versé</b>
          </td>
        </tr>
        <tr>
          <td />
          <td>
            {{ totalSalaire }}
          </td>
          <td />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

.title {
  margin: auto;
  text-align: center;
  font-size: 22px;
}

.lpp-warning {
  font-style: italic;
  font-size: 10px;
}

.total {
  font-weight: bold;
}

</style>
