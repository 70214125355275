import { createApp } from 'vue';
import { createVuestic } from 'vuestic-ui';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Camera from 'simple-vue-camera';
import App from './App.vue';
// eslint-disable-next-line import/no-unresolved, import/extensions
import './registerServiceWorker';
// eslint-disable-next-line import/no-unresolved, import/extensions
import router from './router';
// eslint-disable-next-line import/no-unresolved, import/extensions
import store from './store';
// eslint-disable-next-line import/no-unresolved
import 'vuestic-ui/css';

axios.defaults.baseURL = 'https://api.helpcomptabilite.ch';

createApp(App).use(store).use(router).use(createVuestic())
  .use(VueAxios, axios)
  .component('camera', Camera)
  .mount('#app');
