<template>
  <div class="compteList" ref="target">
    <va-affix class="compteAffixFilter">
      <div class="compteFilter">
        <div class="row filterRow">
          <va-input
            class="mb-4"
            v-model="filter.input"
            label="Filtrer"
            placeholder="Filtrer (TODO)"
          >
            <template #appendInner>
              <va-icon
                name="search"
              />
            </template>
          </va-input>
        </div>
      </div>
    </va-affix>
    <va-data-table
      striped
      hoverable
      footer-clone
      allow-footer-sorting
      animated
      sortable
      sticky-header
      class="compteTable"
      :columns="columns"
      :items="comptes">
      <template #cell(id)="{ source: id }">
        <va-button
          icon="edit"
          flat
          :disabled="!(id)"
          @click="editCompte(id)" />
        <va-button
          icon="delete"
          flat
          :disabled="!(id)"
          @click="removeCompte(id)" />
        {{ id }}
      </template>
    </va-data-table>
    <va-affix :offset-bottom="50">
      <div v-if="showCompteForm">
        <va-form
          id="compteForm"
          tag="form"
          @submit.prevent="addCompte"
        >
          <va-input
            label="numéro"
            v-model="form.id"
            :rules="[value => (value
              && value.length > 0
              && value.length < 5) || 'Mauvais numéro']"
          />
          <va-input
            label="libelle"
            v-model="form.libelle"
          />
          <va-button type="submit" class="mt-2">
            Valider
          </va-button>
        </va-form>
      </div>
      <div class="compteButtons">
        <va-button
          v-if="showCompteForm"
          icon="cancel"
          @click="hideCompteForm()"
        >
          Annuler
        </va-button>
        <va-button
          v-else
          icon="add"
          @click="addCompteForm()"
        >
          Ajouter
        </va-button>
      </div>
    </va-affix>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Compte from '@/types/Compte';

export default defineComponent({
  name: 'CompteList',
  data() {
    const columns = [
      { key: 'id', sortable: true, headerTitle: 'Numéro' },
      { key: 'libelle', sortable: false },
    ];

    return {
      columns,
      showCompteForm: false,
      form: {} as Compte,
      filter: {
        input: '',
      },
      timeout: 0,
      editMode: false,
      accordions: [],
      editedCompte: {} as Compte,
    };
  },
  computed: {
    comptes() {
      return this.$store.getters.comptes.filter(
        (c:Compte) => this.filter.input === ''
          || c.libelle.includes(this.filter.input)
          || String(c.id).includes(this.filter.input),
      ).sort((a: Compte, b: Compte) => String(a.id).localeCompare(String(b.id)));
    },
    isUserAdmin(): boolean {
      return this.$store.getters.user.admin === true;
    },
  },
  setup() {
    onMounted(() => {
      const store = useStore();

      store.dispatch('updateAllComptes');
    });
  },
  methods: {
    addCompteForm() {
      this.editedCompte = {} as Compte;
      this.showCompteForm = true;
    },
    hideCompteForm() {
      this.editedCompte = {} as Compte;
      this.showCompteForm = false;
    },
    editCompte(id:number) {
      this.editedCompte = this.$store.getters.getCompteById(id) as Compte;
      this.showCompteForm = true;
      this.editMode = true;

      // copy data to form
      this.form = {
        id: this.editedCompte.id,
        libelle: this.editedCompte.libelle,
      } as Compte;
    },
    removeCompte(id:number) {
      this.$store.dispatch('removeCompte', {
        id,
      });
    },
    addCompte() {
      console.log(this.form);

      if (this.editMode) {
        this.$store.dispatch('editCompte', {
          id: this.form.id,
          libelle: this.form.libelle,
        } as Compte)
          .then(() => {
            this.showCompteForm = false;
            this.editedCompte = {} as Compte;
            this.editMode = false;

            this.form.id = 0;
            this.form.libelle = '';
          });
      } else {
        this.$store.dispatch('addCompte', {
          id: this.form.id,
          libelle: this.form.libelle,
        }).then(() => {
          this.showCompteForm = false;
          this.editedCompte = {} as Compte;

          this.form.id = 0;
          this.form.libelle = '';
        });
      }
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.compteList {
    margin: 20px;
    height: calc(100vh - var(--titleHeight));
    overflow-y: scroll;
    overflow: hidden;
}

.list-items {
    text-align: left;
}

.compteAffixFilter {
  height:75px;
}

.compteFilter{
  background-color: var(--va-primary);
  color: var(--va-background);
  padding: 5px;
  height: 75px;
}

.compteButtons {
  margin: 20px;
}

.filterRow{
  margin-top: 10px;
}

.compteTable {
  height: calc(100vh - var(--titleHeight) - 75px);
  max-height: calc(100vh - var(--titleHeight) - 75px);
}
</style>
