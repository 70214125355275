import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4625824a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "compteList",
  ref: "target"
}
const _hoisted_2 = { class: "compteFilter" }
const _hoisted_3 = { class: "row filterRow" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "compteButtons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_va_affix = _resolveComponent("va-affix")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_data_table = _resolveComponent("va-data-table")!
  const _component_va_form = _resolveComponent("va-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_va_affix, { class: "compteAffixFilter" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_va_input, {
              class: "mb-4",
              modelValue: _ctx.filter.input,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.input) = $event)),
              label: "Filtrer",
              placeholder: "Filtrer (TODO)"
            }, {
              appendInner: _withCtx(() => [
                _createVNode(_component_va_icon, { name: "search" })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_va_data_table, {
      striped: "",
      hoverable: "",
      "footer-clone": "",
      "allow-footer-sorting": "",
      animated: "",
      sortable: "",
      "sticky-header": "",
      class: "compteTable",
      columns: _ctx.columns,
      items: _ctx.comptes
    }, {
      "cell(id)": _withCtx(({ source: id }) => [
        _createVNode(_component_va_button, {
          icon: "edit",
          flat: "",
          disabled: !(id),
          onClick: ($event: any) => (_ctx.editCompte(id))
        }, null, 8, ["disabled", "onClick"]),
        _createVNode(_component_va_button, {
          icon: "delete",
          flat: "",
          disabled: !(id),
          onClick: ($event: any) => (_ctx.removeCompte(id))
        }, null, 8, ["disabled", "onClick"]),
        _createTextVNode(" " + _toDisplayString(id), 1)
      ]),
      _: 1
    }, 8, ["columns", "items"]),
    _createVNode(_component_va_affix, { "offset-bottom": 50 }, {
      default: _withCtx(() => [
        (_ctx.showCompteForm)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_va_form, {
                id: "compteForm",
                tag: "form",
                onSubmit: _withModifiers(_ctx.addCompte, ["prevent"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_va_input, {
                    label: "numéro",
                    modelValue: _ctx.form.id,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.id) = $event)),
                    rules: [value => (value
              && value.length > 0
              && value.length < 5) || 'Mauvais numéro']
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_va_input, {
                    label: "libelle",
                    modelValue: _ctx.form.libelle,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.libelle) = $event))
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_va_button, {
                    type: "submit",
                    class: "mt-2"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Valider ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onSubmit"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.showCompteForm)
            ? (_openBlock(), _createBlock(_component_va_button, {
                key: 0,
                icon: "cancel",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hideCompteForm()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Annuler ")
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_va_button, {
                key: 1,
                icon: "add",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addCompteForm()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Ajouter ")
                ]),
                _: 1
              }))
        ])
      ]),
      _: 1
    })
  ], 512))
}