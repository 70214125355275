<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { VaFile, useForm } from 'vuestic-ui';
import {
  getEcritureTVAById, editEcritureTVAById, getStandaloneFichiers,
// eslint-disable-next-line import/no-unresolved, import/extensions
} from '@/api/api';
import {
  dateInExerciceComptableRange, roundTwoDecimal, readFile,
// eslint-disable-next-line import/no-unresolved, import/extensions
} from '@/components/Utils';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Ecriture from '@/types/Ecriture';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Compte from '@/types/Compte';
// eslint-disable-next-line import/no-unresolved, import/extensions
import TypeFichier from '@/types/TypeFichier';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Fichier from '@/types/Fichier';
// eslint-disable-next-line import/no-unresolved, import/extensions
import ExerciceComptable from '@/types/ExerciceComptable';

// component's props
interface Props {
  tva: boolean,
  fileInput: Fichier,
}

const props = defineProps<Props>();

// component's event
const emit = defineEmits(['create', 'error']);

// vuex init

const store = useStore();

// computed properties

const comptes = computed(() => store.getters.comptes as Compte[]);

const compteSelect = computed(
  () => comptes.value.filter((compte:Compte) => String(compte.id).length === 4),
);

const exercice = computed(() => store.getters.getSelectedExerciceComptable as ExerciceComptable);

const TVAToggleValues = computed(() => store.getters.getSelectedExerciceComptableTVAValues);

const typesFilesOptions = computed(() => store.getters.typesFichier as TypeFichier);

// component data
let lastDate = new Date();
if (lastDate > exercice.value.end) {
  lastDate = exercice.value.end;
}
const {
  isValid, validate, reset, resetValidation,
} = useForm('formEcriture');

const form = ref({
  ecriture: {
    date: lastDate,
  } as Ecriture,
  tva: 0.077,
  montantTVA: 0.0,
  montantHorsTVA: 0.0,
  pendingFile: {} as VaFile,
  pendingFileType: {} as TypeFichier,
  showChoosingFileModal: false,
  modalFileSelected: {} as Fichier,
  editMode: false,
  formFunc: {
    isValid,
    validate,
    reset,
    resetValidation,
  },
});

/**
* Compute montant and tva montant based on montantTot
*/
function TVAChange() {
  // Si montant Hors TVA != 0 && montant & montant TVA = 0
  // Calcul les deux autres montant
  if (form.value.montantHorsTVA !== 0
          && (form.value.ecriture.montant === 0 || form.value.ecriture.montant === undefined)
          && form.value.montantTVA === 0) {
    if (form.value.tva === 1) {
      form.value.montantHorsTVA = 0;
      form.value.montantTVA = form.value.ecriture.montant;
    } else {
      form.value.ecriture.montant = roundTwoDecimal(
        form.value.montantHorsTVA * (1 + form.value.tva),
      );
      form.value.montantTVA = roundTwoDecimal(
        form.value.ecriture.montant - form.value.montantHorsTVA,
      );
    }
  } else if (form.value.montantHorsTVA === 0
        && form.value.ecriture.montant !== 0
        && form.value.ecriture.montant !== undefined
        && form.value.montantTVA === 0) {
    form.value.montantHorsTVA = roundTwoDecimal(
      form.value.ecriture.montant / (1 + form.value.tva),
    );
    form.value.montantTVA = roundTwoDecimal(
      form.value.ecriture.montant - form.value.montantHorsTVA,
    );
  } else if (form.value.montantHorsTVA === 0
        && (form.value.ecriture.montant === 0 || form.value.ecriture.montant === undefined)
        && form.value.montantTVA !== 0) {
    form.value.montantHorsTVA = roundTwoDecimal(
      form.value.montantTVA / form.value.tva,
    );
    form.value.ecriture.montant = roundTwoDecimal(
      form.value.montantTVA - 0 + form.value.montantHorsTVA,
    );
  } if (form.value.tva === 1) {
    form.value.montantHorsTVA = 0;
    form.value.montantTVA = form.value.ecriture.montant;
  } else {
    // all amount are already written (with old value for example)
    // we check that everything is right based on montant
    form.value.montantHorsTVA = roundTwoDecimal(
      form.value.ecriture.montant / (1 + form.value.tva),
    );
    form.value.montantTVA = roundTwoDecimal(
      form.value.ecriture.montant - form.value.montantHorsTVA,
    );
  }
}

function clearFormData() {
  form.value.ecriture = {} as Ecriture;
  form.value.montantTVA = 0;
  form.value.montantHorsTVA = 0;

  form.value.pendingFile = {} as VaFile;
  form.value.pendingFileType = {} as TypeFichier;
}

/**
 * Add an ecriture with VAT using form data
 */
function addEcritureTVA() {
  console.log('add ecriture with form data');
  console.log(form.value);
  if (!form.value.formFunc.validate()) {
    return;
  }
  if (form.value.ecriture.debit.id === form.value.ecriture.credit.id) {
    store.commit(
      'addMessage',
      {
        message: 'Débit ne peux pas être égal à crédit',
        type: 'warning',
      },
    );
    return;
  }

  if (Object.keys(form.value.pendingFile).length !== 0
   && Object.keys(form.value.pendingFileType).length === 0) {
    store.commit(
      'addMessage',
      {
        message: 'Type Fichier manquant',
        type: 'warning',
      },
    );
    return;
  }

  if (Object.keys(form.value.pendingFile).length !== 0) {
    readFile(form.value.pendingFile)
      .then((data:any) => {
        const fichier = {} as Fichier;
        fichier.data = data;
        // eslint-disable-next-line prefer-destructuring
        fichier.mime = data.split(';')[0].split(':')[1];
        fichier.type = form.value.pendingFileType;
        fichier.nom = `Fichier: ${form.value.ecriture.libelle}`;

        if (form.value.editMode) {
          editEcritureTVAById(
            form.value.ecriture.ID,
            form.value.ecriture,
            form.value.montantTVA,
            form.value.tva,
            fichier,
          )
            .then((response) => {
              if (response) {
                clearFormData();
              } else {
                // error handling ?
              }
              store.dispatch('updateAllEcritures');
            });
        } else {
          store.dispatch(
            'addEcritureTVA',
            {
              ecriture: form.value.ecriture,
              montantTVA: form.value.montantTVA,
              tauxTVA: form.value.tva,
              fichier,
            },
          )
            .then(() => {
              emit('create');
              clearFormData();
            });
        }
      });
  } else if (form.value.editMode) {
    editEcritureTVAById(
      form.value.ecriture.ID,
      form.value.ecriture,
      form.value.montantTVA,
      form.value.tva,
      form.value.modalFileSelected.id !== undefined ? form.value.modalFileSelected : undefined,
    )
      .then((response) => {
        if (response) {
          emit('create');
          clearFormData();
        } else {
          // error handling ?
          emit('error');
        }
        store.dispatch('updateAllEcritures');
      });
  } else {
    let fichier;
    if (props.fileInput.id !== undefined) {
      fichier = props.fileInput;
    } else if (form.value.modalFileSelected.id !== undefined) {
      fichier = form.value.modalFileSelected;
    }

    store.dispatch(
      'addEcritureTVA',
      {
        ecriture: form.value.ecriture,
        montantTVA: form.value.montantTVA,
        tauxTVA: form.value.tva,
        fichier,
      },
    )
      .then(() => {
        emit('create');
        clearFormData();
      });
  }
}

/**
 * Add an ecriture without VAT using form data
 */
function addEcriture() {
  console.log('add ecriture with form data');
  console.log(form.value);
  if (!form.value.formFunc.validate()) {
    return;
  }
  if (form.value.ecriture.debit.id === form.value.ecriture.credit.id) {
    store.commit(
      'addMessage',
      {
        message: 'Débit ne peux pas être égal à crédit',
        type: 'warning',
      },
    );
    return;
  }
  if (Object.keys(form.value.pendingFile).length !== 0
   && Object.keys(form.value.pendingFileType).length === 0) {
    store.commit(
      'addMessage',
      {
        message: 'Type Fichier manquant',
        type: 'warning',
      },
    );
    return;
  }

  if (Object.keys(form.value.pendingFile).length !== 0) {
    readFile(form.value.pendingFile)
      .then((data:any) => {
        const fichier = {} as Fichier;
        fichier.data = data;
        // eslint-disable-next-line prefer-destructuring
        fichier.mime = data.split(';')[0].split(':')[1];
        fichier.type = form.value.pendingFileType;
        fichier.nom = `Fichier: ${form.value.ecriture.libelle}`;

        if (form.value.editMode) {
          store.dispatch('editEcriture', {
            ecriture: form.value.ecriture,
            fichier,
          })
            .then(() => {
              clearFormData();
            });
        } else {
          store.dispatch('addEcriture', {
            ecriture: form.value.ecriture,
            fichier,
          })
            .then(() => {
              emit('create');
              clearFormData();
            });
        }
      });
  } else if (form.value.editMode) {
    store.dispatch('editEcriture', {
      ecriture: form.value.ecriture,
      fichier: form.value.modalFileSelected.id !== undefined
        ? form.value.modalFileSelected : undefined,
    })
      .then(() => {
        clearFormData();
      });
  } else {
    let fichier;
    if (props.fileInput.id !== undefined) {
      fichier = props.fileInput;
    } else if (form.value.modalFileSelected.id !== undefined) {
      fichier = form.value.modalFileSelected;
    }
    store.dispatch('addEcriture', {
      ecriture: form.value.ecriture,
      fichier,
    })
      .then(() => {
        emit('create');
        clearFormData();
      });
  }
}

</script>

<template>
  <va-form
    id="ecritureForm"
    tag="form"
    ref="formEcriture"
    immediate
    @submit.prevent="addEcritureTVA"
    v-if="tva"
  >
    <div class="formGrid">
      <va-date-input
        class="mb-4"
        label="date"
        v-model="form.ecriture.date"
        first-weekday="Monday"
        manual-input
        highlight-weekend
        :allowed-days="(date:Date) => dateInExerciceComptableRange(date, exercice)"
        :allowed-months="(date:Date) => dateInExerciceComptableRange(date, exercice)"
        :allowed-years="(date:Date) => dateInExerciceComptableRange(date, exercice)"
        :rules="[(date:Date) => dateInExerciceComptableRange(date, exercice)]"
      />
      <va-select
        label="debit"
        class="mb-4"
        v-model="form.ecriture.debit"
        autocomplete
        :options="compteSelect"
        :text-by="(cpt: Compte) => cpt.id + ' - ' + cpt.libelle"
        :track-by="(cpt: Compte) => String(cpt.id)"
        :rules="[(cpt:Compte) => cpt || 'Champ requis']"
      />
      <va-select
        label="credit"
        class="mb-4"
        v-model="form.ecriture.credit"
        searchable
        :options="compteSelect"
        :text-by="(cpt: Compte) => cpt.id + ' - ' + cpt.libelle"
        :track-by="(cpt: Compte) => String(cpt.id)"
        :rules="[(cpt:Compte) => cpt || 'Champ requis']"
      />
      <va-input
        label="libelle"
        class="mb-4"
        v-model="form.ecriture.libelle"
        :rules="[(value:string) => (value && value.length > 0) || 'Champ Requis']"
      />
      <div class="formGridTVA">
        <va-radio
          v-for="(option, index) in TVAToggleValues"
          :key="index"
          v-model="form.tva"
          :option="option.value"
          :label="option.label"
          class="mb-4"
          @change="TVAChange()"
        />
      </div>
      <va-input
        label="montant Avec TVA"
        class="mb-4"
        v-model="form.ecriture.montant"
        @change="TVAChange()"
        mask="numeral"
        :rules="[(value:string) => (value && value.length > 0) || 'Champ Requis']"
      />
      <va-input
        label="montantTVA"
        class="mb-4"
        v-model="form.montantTVA"
        @change="TVAChange()"
        mask="numeral"
        disabled
      />
      <va-input
        label="montant hors TVA"
        class="mb-4"
        v-model="form.montantHorsTVA"
        @change="TVAChange()"
        mask="numeral"
        disabled
      />
      <template v-if="fileInput === undefined">
        <div class="formGridTVA">
          <va-file-upload
            v-model="form.pendingFile"
            class="mb-4"
            type="single"
            file-types="image/*,.pdf"
            :color="form.editMode && form.ecriture.fichier !== undefined ? 'Warning' : 'Primary'"
            :upload-button-text="form.editMode && form.ecriture.fichier !== undefined ? 'Changer le fichier ' : 'Choisir un fichier'"
            undo-button-text="Annuler"
            deleted-file-message="Supprimer"
            :disabled="form.modalFileSelected.id !== undefined"
          />
        </div>
        <va-button
          @click="form.showChoosingFileModal = true"
          class="mb-4"
          :disabled="form.pendingFile !== undefined"
          v-if="form.pendingFile === undefined"
        >
          Lié à un fichier existant
        </va-button>
        <va-select
          label="Type Fichier"
          class="mb-4"
          :options="typesFilesOptions"
          v-model="form.pendingFileType"
          :text-by="(tfi: TypeFichier) => tfi.libelle"
          :track-by="(tfi: TypeFichier) => String(tfi.id)"
          :rules="[(tfi:TypeFichier) => tfi || 'Champ requis']"
          searchable
          v-else
        />
        <va-button
          preset="secondary"
          class="mr-6 mb-2"
          round
          icon="close"
          size="small"
          @click="form.modalFileSelected = {} as Fichier"
          v-if="form.modalFileSelected.id !== undefined"
        >
          {{ form.modalFileSelected.nom }}
        </va-button>
      </template>
    </div>
    <va-button type="submit" class="mb-4" color="success">
      Valider
    </va-button>
  </va-form>
  <va-form
    id="ecritureForm"
    tag="form"
    ref="formEcriture"
    immediate
    @submit.prevent="addEcriture"
    v-else
  >
    <div class="formGrid">
      <va-date-input
        class="mb-4"
        label="date"
        v-model="form.ecriture.date"
        first-weekday="Monday"
        manual-input
        highlight-weekend
        :allowed-days="(date:Date) => dateInExerciceComptableRange(date, exercice)"
        :allowed-months="(date:Date) => dateInExerciceComptableRange(date, exercice)"
        :allowed-years="(date:Date) => dateInExerciceComptableRange(date, exercice)"
        :rules="[(date:Date) => dateInExerciceComptableRange(date, exercice)]"
      />
      <va-select
        label="debit"
        class="mb-4"
        v-model="form.ecriture.debit"
        searchable
        :options="compteSelect"
        :text-by="(cpt: Compte) => cpt.id + ' - ' + cpt.libelle"
        :track-by="(cpt: Compte) => String(cpt.id)"
        :rules="[(cpt:Compte) => cpt || 'Champ requis']"
      />
      <va-select
        label="credit"
        class="mb-4"
        v-model="form.ecriture.credit"
        searchable
        :options="compteSelect"
        :text-by="(cpt: Compte) => cpt.id + ' - ' + cpt.libelle"
        :track-by="(cpt: Compte) => String(cpt.id)"
        :rules="[(cpt:Compte) => cpt || 'Champ requis']"
      />
      <va-input
        label="libelle"
        class="mb-4"
        v-model="form.ecriture.libelle"
        :rules="[value => (value && value.length > 0) || 'Field is required']"
      />
      <va-input
        label="montant"
        class="mb-4"
        v-model="form.ecriture.montant"
        mask="numeral"
        :rules="[(value:string) => (value && value.length > 0) || 'Champ Requis']"
      />
      <template v-if="fileInput === undefined">
        <va-file-upload
          v-model="form.pendingFile"
          class="mb-4"
          type="single"
          file-types="image/*,.pdf"
          :color="form.editMode && form.ecriture.fichier !== undefined ? 'Warning' : 'Primary'"
          :upload-button-text="form.editMode && form.ecriture.fichier !== undefined ? 'Changer le fichier ' : 'Choisir un fichier'"
          undo-button-text="Annuler"
          deleted-file-message="Supprimer"
          :disabled="form.modalFileSelected.id !== undefined"
        />
        <va-button
          @click="form.showChoosingFileModal = true"
          class="mb-4"
          :disabled="form.pendingFile !== undefined"
          v-if="form.pendingFile === undefined"
        >
          Lié à un fichier existant
        </va-button>
        <va-select
          label="Type Fichier"
          class="mb-4"
          :options="typesFilesOptions"
          v-model="form.pendingFileType"
          :text-by="(tfi: TypeFichier) => tfi.libelle"
          :track-by="(tfi: TypeFichier) => String(tfi.id)"
          :rules="[(tfi:TypeFichier) => tfi || 'Champ requis']"
          searchable
          v-else
        />
        <va-button
          preset="secondary"
          class="mr-6 mb-2"
          round
          icon="close"
          size="small"
          @click="form.modalFileSelected = {} as Fichier"
          v-if="form.modalFileSelected.id !== undefined"
        >
          {{ form.modalFileSelected.nom }}
        </va-button>
      </template>
      <va-button type="submit" class="mb-4" color="success">
        Valider
      </va-button>
    </div>
  </va-form>
</template>

<style scoped>
.formGrid {
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
}

.formGridTVA {
  grid-column-start: 1;
}
</style>
