<template>
  <div class="plan">
    <h1 class="title">Plan Comptables</h1>
    <CompteList />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
// eslint-disable-next-line import/no-unresolved, import/extensions
import CompteList from '@/components/CompteList.vue'; // @ is an alias to /src

export default defineComponent({
  name: 'comptaPlan',
  components: {
    CompteList,
  },
});
</script>

<style scoped>

.title {
  margin: auto;
  text-align: center;
  font-size: 22px;
}

</style>
