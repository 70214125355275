import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "comptabilites" }
const _hoisted_3 = { class: "titleLogo titleDiv" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_image = _resolveComponent("va-image")!
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_card_title = _resolveComponent("va-card-title")!
  const _component_va_card_content = _resolveComponent("va-card-content")!
  const _component_va_card = _resolveComponent("va-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_va_image, {
          contain: "",
          class: "flex titleLogo",
          src: _ctx.logo,
          alt: "Logo HelpComptabilité"
        }, null, 8, ["src"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exercices, (exercice) => {
          return (_openBlock(), _createBlock(_component_va_card, {
            class: "flex md2 ld1 comCards",
            key: exercice.id,
            to: {
            name: 'bilan',
            params: {
              comptabilite: _ctx.selectedComptabiliteId,
              exercice: exercice.id,
            },
          }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_va_card_title, null, {
                default: _withCtx(() => [
                  (exercice.locked === true)
                    ? (_openBlock(), _createBlock(_component_va_icon, {
                        key: 0,
                        name: "lock"
                      }))
                    : (_openBlock(), _createBlock(_component_va_icon, {
                        key: 1,
                        name: "lock_open"
                      }))
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_va_card_content, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(exercice.start.toISOString().split('T')[0]) + " -> " + _toDisplayString(exercice.end.toISOString().split('T')[0]), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_va_card, {
          class: "flex md2 ld1 comCards",
          to: {
            name: 'fiche salaire',
            params: {
              comptabilite: _ctx.selectedComptabiliteId,
            },
          }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_va_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode(" Actions ")
              ]),
              _: 1
            }),
            _createVNode(_component_va_card_content, null, {
              default: _withCtx(() => [
                _createTextVNode("Fiche Salaires")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ])
  ]))
}