<!-- eslint-disable vuejs-accessibility/media-has-caption -->
<template>
  <div class="fichierList box">
    <div class="header">
      <div class="fichierFilter">
        <h2>Filtre</h2>
        <div class="fichierFilterGrid">
          <va-select
            label="Type Fichier"
            v-model="filters.type"
            clearable
            autocomplete
            :options="typesOptions"
            ref="filterTypeSelect"
            placeholder="Tous les types"
            :text-by="(ty: TypeFichier) => ty.libelle"
            :track-by="(ty: TypeFichier) => String(ty.id)"
          />
          <va-checkbox
            v-model="filters.orphans"
            class="mb-6"
            label="Fichier sans écritures"
          />
        </div>
      </div>
    </div>
    <va-data-table
      striped
      hoverable
      footer-clone
      allow-footer-sorting
      animated
      sortable
      sticky-header
      class="fichierTable"
      :loading="loadingFiles"
      :columns="columns"
      :items="fichiers">
      <template #cell(id)="{ source: id }">
        <va-button-group preset="plain">
          <va-button
            icon="visibility"
            flat
            :disabled="!(id)"
            @click="showfichier(id)" />
          <va-button
            icon="edit"
            flat
            :color="filters.orphans ? 'primary' : 'warning'"
            :disabled="!(id)"
            @click="editfichier(id)" />
          <va-button
            icon="delete"
            flat
            :disabled="!(id)"
            v-if="filters.orphans"
            @click="removefichier(id)" />
        </va-button-group>
      </template>
      <template #cell(date)="{ value }">
        {{ new Date(value).toISOString().split('T')[0] }}
      </template>
    </va-data-table>
    <div class="footer">
      <va-form
        id="fichierForm"
        class="fichierForm"
        tag="form"
        @submit.prevent="addfichier"
        v-if="showfichierForm"
      >
        <div class="fichierFooterGrid">
          <va-input
            class="mb-4"
            label="nom"
            v-model="form.nom"
            :rules="[(value) => (value && value.length > 0) || 'Champ requis']"
          />
          <va-file-upload
            v-model="formFileUpload"
            class="mb-4"
            color="white"
            type="single"
            file-types="image/*,.pdf"
            upload-button-text="Choisir un fichier"
            undo-button-text="Annuler"
            deleted-file-message="Supprimer"
          />
          <va-button
            class="mb-4"
            :enable="formFileUpload === undefined">
            <!-- TODO do something with a library like jscanify when it's updated-->
            <!-- or directly with open csv, idk-->
            Prendre une photo
          </va-button>
          <va-select
            label="Type"
            class="mb-4"
            v-model="form.type"
            autocomplete
            clearable
            :options="typesOptions"
            :text-by="(tfi: TypeFichier) => tfi.libelle"
            :track-by="(tfi: TypeFichier) => String(tfi.id)"
          />
          <va-button
            type="submit"
            class="mb-4"
            color="white">
            Valider
          </va-button>
        </div>
      </va-form>
      <div class="fichierButtons">
        <va-button
          v-if="showfichierForm"
          icon="cancel"
          @click="hidefichierForm()"
        >
          Annuler
        </va-button>
        <va-button
          v-else
          icon="add"
          @click="addfichierForm()"
        >
          Ajouter
        </va-button>
      </div>
    </div>
  </div>
  <va-modal
    v-model="showModal"
    title="Visualisation"
    fullscreen
    blur
  >
    <div class="modalGrid">
      <div class="modalFile">
        <div>
          <vue-pdf-embed
            v-if="modal.fichier.mime === 'application/pdf'"
            :source="modal.fichier.data"
          />
          <va-image
            class="w-full md:w-1/2 lg:w-1/3"
            :src="modal.fichier.data"
            v-else
          />
        <!-- TODO If an ecriture is link to file
        -> display ecriture -->
        </div>
      </div>
      <div class="modalEcriture">
        <EcritureForm
          :tva="comptabilite.TVA === '1'"
          :file-input="modal.fichier"
          @create="newFileEcriture"
          v-if="modal.ecriture.length === 0"
        />
        <EcritureView
          v-for="ecriture in modal.ecriture"
          v-bind:key="ecriture.ID"
          :ecriture="ecriture"
          v-else />
      </div>
    </div>
  </va-modal>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { VaFile, VaFileUpload } from 'vuestic-ui';
import { useStore } from 'vuex';
import VuePdfEmbed from 'vue-pdf-embed';
import EcritureView from './EcritureView.vue';
import EcritureForm from './EcritureForm.vue';
// eslint-disable-next-line import/no-unresolved, import/extensions
import TypeFichier from '@/types/TypeFichier';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Fichier from '@/types/Fichier';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Ecriture from '@/types/Ecriture';
import {
  getFichierById,
// eslint-disable-next-line import/no-unresolved, import/extensions
} from '@/api/api';
// eslint-disable-next-line import/no-unresolved, import/extensions
import ExerciceComptable from '@/types/ExerciceComptable';

export default defineComponent({
  name: 'fichierList',
  data() {
    const columns = [
      { key: 'id', sortable: true, label: 'Actions' },
      { key: 'nom', sortable: true },
      { key: 'type.libelle', sortable: true, label: 'Type' },
      {
        key: 'createdAt', name: 'date', sortable: false, label: 'Téléchargé le',
      },
    ];

    const store = useStore();

    return {
      columns,
      showfichierForm: false,
      showModal: false,
      scanModal: false,
      modal: {
        fichier: {} as Fichier,
        ecriture: new Array<Ecriture>(),
      },
      editMode: false,
      filters: {
        orphans: false,
        type: {} as TypeFichier,
      },
      form: {} as Fichier,
      formFileUpload: {} as VaFile,
      editedFichier: {} as Fichier,
      store,
      loadingFiles: false,
    };
  },
  components: {
    VuePdfEmbed,
    EcritureView,
    EcritureForm,
  },
  computed: {
    typesOptions(): TypeFichier[] {
      return this.store.getters.typesFichier;
    },
    rawFichiers() : Fichier[] {
      if (this.filters.orphans) {
        return this.store.getters.standaloneFiles;
      }
      return this.store.getters.fichiers;
    },
    fichiers(): Fichier[] {
      if (this.filters.type.id !== undefined) {
        return this.rawFichiers.filter((fi:Fichier) => (fi.type.id === this.filters.type.id));
      }
      return this.rawFichiers;
    },
    isUserAdmin(): boolean {
      return this.store.getters.user.admin === true;
    },
    comptabilite(): ExerciceComptable {
      return this.store.getters.getSelectedComptabilite;
    },
  },
  mounted() {
    this.store.dispatch('updateStandaloneFichier');
    this.store.dispatch('updateAllTypesFichier').then(
      () => this.store.dispatch('updateAllFichiers'),
    );
  },
  watch: {
    'filters.orphans': function (newVal: boolean, oldVal: boolean) {
      if (newVal) {
        this.store.dispatch('updateStandaloneFichier');
      }
    },
  },
  methods: {
    addfichierForm() {
      this.editedFichier = {} as Fichier;
      this.showfichierForm = true;
    },
    hidefichierForm() {
      this.editedFichier = {} as Fichier;
      this.showfichierForm = false;
    },
    showfichier(id:number) {
      this.loadingFiles = true;
      this.modal.fichier = {} as Fichier;
      this.modal.ecriture = new Array<Ecriture>();
      getFichierById(id).then((response) => {
        if (response !== undefined) {
          this.modal.fichier = response.fichier;
          if (response.ecriture !== undefined) {
            this.modal.ecriture = response.ecriture;
          }
          console.log(this.modal);
          this.showModal = true;
        }
      }).finally(() => {
        this.loadingFiles = false;
      });
    },
    newFileEcriture() {
      this.store.dispatch('updateStandaloneFichier');
      this.showfichier(this.modal.fichier.id);
    },
    editfichier(id:number) {
      this.editedFichier = this.$store.getters.getFichiersById(id) as Fichier;
      this.showfichierForm = true;
      this.editMode = true;

      // copy data to form
      this.form = {
        id: this.editedFichier.id,
        nom: this.editedFichier.nom,
      } as Fichier;
    },
    removefichier(id:number) {
      this.$store.dispatch('removefichier', {
        id,
      });
    },
    addfichier() {
      if (!(this.form.nom && this.form.nom.length > 0)
      || this.formFileUpload === undefined
      || !(this.form.type !== undefined && this.form.type)
      ) {
        // invalid inputs
        return;
      }
      console.log(this.formFileUpload);

      if (this.editMode) {
        /* this.$store.dispatch('editfichier', {
          id: this.form.id,
          libelle: this.form.libelle,
        } as fichier)
          .then(() => {
            this.showfichierForm = false;
            this.editedfichier = {} as fichier;
            this.editMode = false;

            this.form.id = 0;
            this.form.libelle = '';
          }); */
      } else {
        this.readFile(this.formFileUpload)
          .then((data:any) => {
            this.form.data = data;
            // eslint-disable-next-line prefer-destructuring
            this.form.mime = data.split(';')[0].split(':')[1];
            console.log(this.form);
            this.$store.dispatch(
              'addFichier',
              this.form,
            ).then(() => {
              this.showfichierForm = false;
              this.editedFichier = {} as Fichier;
              this.form = {} as Fichier;
            });
          });
      }
    },
    readFile(file:any) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.onerror = reject;
        fr.readAsDataURL(file);
      });
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fichierList {
    height: calc(100vh - var(--titleHeight));
    overflow-y: scroll;
    overflow: hidden;
}

.list-items {
    text-align: left;
}

.fichierAffixFilter {
  height:75px;
}

.fichierFilter{
  background-color: var(--va-primary);
  color: var(--va-background);
  padding: 5px;
}

.fichierButtons {
  margin: 20px;
}

.filterRow{
  margin-top: 10px;
}

.fichierTable {
  height: calc(100vh - var(--titleHeight) - 75px);
  max-height: calc(100vh - var(--titleHeight) - 75px);
}

.fichierForm {
  background-color: rgba(0, 0, 0, 0.12);
}

.box {
  display: flex;
  flex-flow: column;
}

.box .header {
  flex: 0 1 auto;
  background-color: var(--va-primary);
  color: var(--va-text-inverted);
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .footer {
  flex: 0 1 auto;
  background-color:rgb(220, 220, 220);
}

.fichierFooterGrid {
  padding: 10px;
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
}

.fichierFilterGrid {
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
}

.modalGrid {
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
}
</style>
