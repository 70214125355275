import { useStore } from 'vuex';

// eslint-disable-next-line import/no-unresolved, import/extensions
import ExerciceComptable from '@/types/ExerciceComptable';

export const monetaryFormat = new Intl.NumberFormat('fr-CH', {
  style: 'currency',
  currency: 'CHF',

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers
  // , but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const timeFormat = new Intl.DateTimeFormat('fr-CH', {
  timeStyle: 'medium',
  dateStyle: 'short',
});

/**
 * @param date Date to be checked
 * @returns True if date is in Exercice Comptable Range
 */
export function dateInExerciceComptableRange(date: Date, exercice: ExerciceComptable) : boolean {
  const min = new Date(exercice.start);
  const max = new Date(exercice.end);

  // min.setDate(min.getDate() - 1);
  // max.setDate(max.getDate() + 1);

  return date >= min && date <= max;
}

/**
 * Round the input number to the nearest 2 decimal value
 * @param input number to be rounded
 * @returns rounded number
 */
export function roundTwoDecimal(value: number):number {
  return Number((Math.round((value + Number.EPSILON) * 100) / 100).toFixed(2));
}

/**
 * Read the file and return the file's blob data
 * @param file
 * @returns
 */
export function readFile(file:any) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = reject;
    fr.readAsDataURL(file);
  });
}
