<template>
  <div class="home">
    <div class="login" v-if="!isLogged">
      <div class="titleLogo titleDiv">
        <va-image
          fit="contain"
          class="flex titleLogo"
          :src="logo"
          alt="Logo HelpComptabilité" />
        Logiciel Comptable
      </div>
      <va-form class="loginForm">
        <h1 class="title">Login</h1>
        <va-input
          class="mb-4 mr-4 loginInput"
          label="Utilisateur"
          v-model="form.comUsername"
          name="comUsername"
          id="comUsername"
          :rules="[value => (value && value.length > 0) || 'Field is required']"
        />
        <va-input
          class="mb-4 mr-4 loginInput"
          label="Mot de passe"
          type="password"
          v-model="form.comPassword"
          name="comPassword"
          id="comPassword"
          :rules="[value => (value && value.length > 0) || 'Field is required']"
        />
        <va-button
          class="mb-4 mr-4"
          @click="login()"
          :loading="isLoading"
        >
          Connexion
        </va-button>
      </va-form>
    </div>
    <div class="comptabilites" v-else>
      <div class="titleLogo titleDiv">
        <va-image
          fit="contain"
          class="flex titleLogo"
          :src="logo"
          alt="Logo HelpComptabilité" />
        Logiciel Comptable
      </div>
      <div class="row">
        <va-card
          class="flex md2 ld1 comCards"
          v-for="comptabilite in comptabilites"
          :key="comptabilite.id"
          :to="{ name: 'comptabilite', params: { comptabilite: comptabilite.id } }"
        >
          <!-- Logo -->
          <va-image
            src="https://picsum.photos/50/50"
            style="height: 50px;"
            fit="contain"
          />
          <!-- End Logo -->
          <va-card-title>{{ comptabilite.nom }}</va-card-title>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
// eslint-disable-next-line import/no-unresolved, import/extensions
import homeLogo from '@/assets/helpcomptabilite.png';

const data = {
  form: {
    comUsername: '',
    comPassword: '',
  },
  logo: homeLogo,
};

export default defineComponent({
  name: 'comptaHome',
  data() {
    return data;
  },
  components: {
  },
  computed: {
    isLogged() {
      return this.$store.getters.logged;
    },
    comptabilites() {
      return this.$store.getters.comptabilites;
    },
  },
  methods: {
    login() {
      this.$store.dispatch('login', {
        username: this.form.comUsername,
        password: this.form.comPassword,
      })
        .then(() => {
          if (this.isLogged) {
            this.$store.dispatch('updateAllComptabilites');
          } else {
            // this.$vaToast.init({ message: 'Échec de connexion', color: 'danger' });
          }
        });
    },
  },
});
</script>

<style>

.loginForm{
  text-align: center;
}

.login {
  margin: auto;
  max-width: 400px;
}

.loginInput{
  width: 100%;
}

.comCards{
  margin: 20px;
}

.titleLogo {
  max-width: 400px;
  max-height: 100px;
  margin: auto;
  text-align:center;
}

.titleDiv {
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
