<template>
  <div class="home">
    <div class="comptabilites">
      <div class="titleLogo titleDiv">
        <va-image contain class="flex titleLogo" :src="logo" alt="Logo HelpComptabilité" />
      </div>
      <div class="row">
        <va-card
          class="flex md2 ld1 comCards"
          v-for="exercice in exercices"
          :key="exercice.id"
          :to="{
            name: 'bilan',
            params: {
              comptabilite: selectedComptabiliteId,
              exercice: exercice.id,
            },
          }"
        >
          <va-card-title>
            <va-icon name="lock" v-if="exercice.locked === true" />
            <va-icon name="lock_open" v-else />
          </va-card-title>
          <va-card-content>{{ exercice.start.toISOString().split('T')[0] }} -> {{ exercice.end.toISOString().split('T')[0] }} </va-card-content>
        </va-card>
      </div>
      <div class="row">
        <va-card
          class="flex md2 ld1 comCards"
          :to="{
            name: 'fiche salaire',
            params: {
              comptabilite: selectedComptabiliteId,
            },
          }"
        >
          <va-card-title>
            Actions
          </va-card-title>
          <va-card-content>Fiche Salaires</va-card-content>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
// eslint-disable-next-line import/no-unresolved, import/extensions
import homeLogo from '@/assets/helpcomptabilite.png';
// eslint-disable-next-line import/no-unresolved, import/extensions
import ExerciceComptable from '@/types/ExerciceComptable';

const data = {
  exercices: Array<ExerciceComptable>(),
  logo: homeLogo,
  loading: false,
};

export default defineComponent({
  name: 'comptaHome',
  data() {
    return data;
  },
  computed: {
    selectedComptabiliteId(): any {
      return this.$route.params.comptabilite;
    },
  },
  mounted() {
    const route = useRoute();
    const store = useStore();

    store.dispatch('updateSelectedComptabilite', route.params.comptabilite);

    this.getAllExercicesComptables();
  },
  methods: {
    getAllExercicesComptables() {
      this.loading = true;

      const store = useStore();

      return store.dispatch('updateAllExercicesComptable')
        .then((response: ExerciceComptable[]) => {
          if (response.length === 1) {
            // if there is only one exercice comptable
            // directly route to the bilan
            this.$router.push({
              name: 'bilan',
              params: {
                comptabilite: this.selectedComptabiliteId,
                exercice: response[0].id,
              },
            });
          }
          this.exercices = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
</script>
