<template>
  <div class="bilan">
    <h1 class="title">Bilan</h1>
    <div class="row align--center gutter--md">
      <va-button
        class="flex navButton"
        v-if="selectedComptabilite !== null"
        :to="{
          name: 'journal',
          params: {
            comptabilite: selectedComptabilite,
            exercice: selectedExercice,
          },
        }">
        Écritures
      </va-button>
      <va-button
        class="flex navButton"
        v-if="selectedComptabilite !== null"
        :to="{
          name: 'plan comptable',
          params: {
            comptabilite: selectedComptabilite,
            exercice: selectedExercice,
          },
        }">
        Plan Comptable
      </va-button>
      <va-button
        class="flex navButton"
        v-if="selectedComptabilite !== null"
        :to="{
          name: 'fichier',
          params: {
            comptabilite: selectedComptabilite,
            exercice: selectedExercice,
          },
        }">
        Fichiers
      </va-button>
    </div>
    <div class="filter">
      <div class="row">
        <va-select
          class="mb-4 flex md2 select"
          label="Exercice Comptable"
          v-model="chosenPeriod"
          disabled
          :options="exercicesComptable"
          :text-by="(option) => `${option.debut} -> ${option.fin}`"
          :value-by="(option) => option.id" />
        <va-divider vertical />
        <va-select
          class="mb-4 flex md2 select"
          label="Période"
          v-model="chosenQuarterly"
          :options="quarterlies"
          :text-by="(option) => option.text"
          :value-by="(option) => [option.start, option.end]"
          track-by="id" />
        <va-divider vertical />
        <va-date-input
          class="mb-4 flex md2 select"
          label="Début"
          v-model="startPeriod"
          placeholder="Début"
          manual-input
          show-other-months
        />
        <va-divider vertical />
        <va-date-input
          class="mb-4 flex md2 select"
          label="Fin"
          v-model="endPeriod"
          placeholder="Fin"
          manual-input
          show-other-months
        />
      </div>
    </div>
    <div class="bilanData">
      <div class="rowBilan row">
        <va-button-toggle
          outline
          v-model="bilanView"
          class="flex md3 listButtonToggle"
          :options="[
            { label: 'Simple', value: 'simple' },
            { label: 'Détaillée', value: 'details' },
          ]"
        />
        <va-button
          :rounded="false"
          class="flex md3 listButtonExport"
          v-on:click="exportBilan()"
          :href="exportUrl"
          :download="exportFilename">
          Exporter
        </va-button>
      </div>
      <va-accordion
        v-model="accordion1"
        class="bilanAccordion"
        inset
        v-if="bilanView === 'simple'"
      >
        <va-collapse
          v-for="lvl1 in bilan"
          :key="lvl1.id"
          class="bilanCollapse"
          stateful
          icon="home"
        >
          <template #header>
            <div class="collHeaderContent">
              <span class="collNo">
                {{lvl1.id}}
              </span>
              <span class="collLibelle">
                {{lvl1.libelle}}
              </span>
              <span class="collMontant">
                {{ displayCurrency(lvl1.montant) }}
              </span>
            </div>
          </template>
          <va-accordion
            v-model="accordion2"
            class="bilanAccordion"
          >
            <va-collapse
              v-for="lvl2 in lvl1.children"
              :key="lvl2.id"
              class="bilanCollapse"
              stateful
              icon="home"
            >
              <template #header>
                <div class="collHeaderContent">
                  <span class="collNo">
                    {{lvl2.id}}
                  </span>
                  <span class="collLibelle">
                    {{lvl2.libelle}}
                  </span>
                  <span class="collMontant">
                    {{ displayCurrency(lvl2.montant) }}
                  </span>
                </div>
              </template>
              <va-accordion
                v-model="accordion3"
                class="bilanAccordion"
              >
                <va-collapse
                  v-for="lvl3 in lvl2.children"
                  :key="lvl3.id"
                  class="bilanCollapse"
                  stateful
                  icon="home"
                >
                  <template #header>
                    <div class="collHeaderContent">
                      <span class="collNo">
                        {{lvl3.id}}
                      </span>
                      <span class="collLibelle">
                        {{lvl3.libelle}}
                      </span>
                      <span class="collMontant">
                        {{ displayCurrency(lvl3.montant) }}
                      </span>
                    </div>
                  </template>
                  <va-accordion
                    v-model="accordion4"
                    class="bilanAccordion"
                  >
                    <va-collapse
                      v-for="lvl4 in lvl3.children"
                      :key="lvl4.id"
                      class="bilanCollapse"
                      stateful
                      icon="home"
                    >
                      <template #header>
                        <div class="collHeaderContent">
                          <span class="collNo">
                            {{lvl4.id}}
                          </span>
                          <span class="collLibelle">
                            {{lvl4.libelle}}
                          </span>
                          <span class="collMontant">
                            {{ displayCurrency(lvl4.montant) }}
                          </span>
                        </div>
                      </template>
                      {{lvl4.libelle}}
                    </va-collapse>
                  </va-accordion>
                </va-collapse>
              </va-accordion>
            </va-collapse>
          </va-accordion>
        </va-collapse>
      </va-accordion>
      <div v-else>
        <table
          class="va-table va-table--hoverable va-table--striped bilanTable">
          <thead>
            <tr>
              <th>Numéro</th>
              <th>Libellé</th>
              <th>Montant</th>
            </tr>
          </thead>
          <template
            v-for="lvl1 in bilan"
            :key="lvl1.id">
            <tr class="bilanTRLvl1">
              <td>
                {{lvl1.id}}
              </td>
              <td>
                {{lvl1.libelle}}
              </td>
              <td class="bilanTDAmount">
                {{ displayCurrency(lvl1.montant) }}
              </td>
            </tr>
            <template
              v-for="lvl2 in lvl1.children"
              :key="lvl2.id">
              <template
                v-for="lvl3 in lvl2.children"
                :key="lvl3.id">
                <template
                  v-for="lvl4 in lvl3.children"
                  :key="lvl4.id"
                >
                  <template v-if="lvl4.montant">
                    <tr>
                      <td>
                        {{lvl4.id}}
                      </td>
                      <td>
                        {{lvl4.libelle}}
                      </td>
                      <td class="bilanTDAmount">
                        {{ displayCurrency(lvl4.montant) }}
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// TODO use composition API https://vuejs.org/guide/extras/composition-api-faq.html
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
// eslint-disable-next-line import/no-unresolved, import/extensions
import ExerciceComptable from '@/types/ExerciceComptable';

export default defineComponent({
  name: 'comptaBilan',
  components: {
  },
  data() {
    return {
      collActif: false,
      collPassif: false,
      collCA: false,
      collCharges: false,
      collCharges2: false,
      collCharges3: false,
      collResultat: false,
      collResultatExtra: false,
      collCloture: false,
      accordion1: [],
      accordion2: [],
      accordion3: [],
      accordion4: [],
      chosenPeriod: 0,
      chosenQuarterly: '',
      startPeriod: new Date(),
      endPeriod: new Date(),
      bilanView: 'simple',
      watchTrigger: 'Trigger',
      exportUrl: '#',
      exportFilename: '',
      uploadFichier: [],
    };
  },
  computed: {
    changedPeriod() {
      return {
        start: (this as any).startPeriod,
        end: (this as any).endPeriod,
      };
    },
    selectedComptabilite() {
      return this.$route.params.comptabilite;
    },
    selectedExercice() {
      return this.$route.params.exercice;
    },
    bilan() {
      return this.$store.getters.bilan;
    },
    comptes() {
      return this.$store.getters.comptes;
    },
    exercicesComptable() {
      return this.$store.getters.exercicesComptable;
    },
    selectedExerciceComptable() {
      return this.$store.getters.getExerciceComptableById(this.$route.params.exercice);
    },
    quarterlies() {
      // get selected exercice comptable
      // extract quarterly from the range
      if ((this as any).selectedExerciceComptable != null) {
        const startPeriod = new Date((this as any).selectedExerciceComptable.debut);
        const endPeriod = new Date((this as any).selectedExerciceComptable.fin);

        const startQuarter = Math.floor((startPeriod.getMonth() + 3) / 3);
        const endQuarter = Math.floor((endPeriod.getMonth() + 3) / 3);

        const quarters = (this as any).getQuarterBetween(
          startQuarter,
          startPeriod.getFullYear(),
          endQuarter,
          endPeriod.getFullYear(),
        );

        const quartersWithRange = quarters.map(
          (quarter:any) => (this as any).getQuarterRange(quarter.quarter, quarter.year),
        );

        return quartersWithRange;
      }
      return [(this as any).getQuarterRange(1, 2021)];
    },
  },
  watch: {
    // TODO watch chosen period and change the bilan accordingly
    chosenQuarterly(newValue) {
      [this.startPeriod, this.endPeriod] = newValue;
    },
    exercicesComptable(newValue) {
      const firstExerciceComptable:ExerciceComptable = this.selectedExerciceComptable;
      this.startPeriod = new Date(firstExerciceComptable.start);
      this.endPeriod = new Date(firstExerciceComptable.end);
    },
    changedPeriod(newPeriod) {
      const startRange = `${newPeriod.start.getDate()}-${newPeriod.start.getMonth() + 1}-${newPeriod.start.getFullYear()}`;
      const endRange = `${newPeriod.end.getDate()}-${newPeriod.end.getMonth() + 1}-${newPeriod.end.getFullYear()}`;

      // wait for updateSelectedExercice to finish
      console.log(`dispatch route:${this.selectedExercice} store:${this.$store.getters.selectedExerciceComptableId}`);
      this.$store.dispatch('updateBilan', {
        start: startRange,
        end: endRange,
      });
    },
  },
  methods: {
    comptesByNo(no: number) {
      return this.$store.getters.getCompteById(no);
    },
    displayCurrency(amount: number) {
      return new Intl.NumberFormat('fr-CH', { style: 'currency', currency: 'CHF' }).format(amount).replace(' ', '\'');
    },
    getQuarterBetween(startQuarter:number, startYear:number, endQuarter:number, endYear: number) {
      let idxQuarter = startQuarter;
      let idxYear = startYear;

      const quarterlies: any[] = [];

      while (idxQuarter < endQuarter || idxYear < endYear) {
        quarterlies.push({ quarter: idxQuarter, year: idxYear });
        if (idxQuarter === 4) {
          idxQuarter = 0;
          idxYear += 1;
        } else {
          idxQuarter += 1;
        }
      }
      quarterlies.push({ quarter: idxQuarter, year: idxYear });

      return quarterlies;
    },
    getQuarterRange(quarter:number, year:number) {
      return {
        id: year * 10 + quarter,
        text: `Q${quarter} ${year}`,
        start: new Date(year, quarter * 3 - 3, 1),
        end: new Date(year, quarter * 3, 0),
      };
    },
    exportBilan() {
      const separator = ';';
      let data = `Numéro${separator}Libellé${separator}Montant\r\n`;

      Object.values(this.bilan).forEach((lvl1:any) => {
        // data += `${lvl1.id}${separator}${lvl1.libelle}${separator}${lvl1.montant}\r\n`;
        Object.values(lvl1.children).forEach((lvl2:any) => {
          Object.values(lvl2.children).forEach((lvl3:any) => {
            try {
              Object.values(lvl3.children).forEach((lvl4:any) => {
                data += `${lvl4.id}${separator}${lvl4.libelle}${separator}${lvl4.montant}\r\n`;
              });
            } catch (error) {
              console.error(error);
              // expected output: ReferenceError: nonExistentFunction is not defined
              // Note - error messages will vary depending on browser
            }
          });
        });
      });

      console.log(data);

      const csvData = encodeURIComponent(data);
      this.exportUrl = `data:text/plain;charset=utf-8,${csvData}`;
      this.exportFilename = 'export.csv';
    },
  },
  setup() {
    onMounted(() => {
      const route = useRoute();
      const store = useStore();
      // const currentYear = new Date().getFullYear();
      console.log(`route params compta:${route.params.comptabilite} exercice:${route.params.exercice}`);

      store.dispatch('updateSelectedComptabilite', route.params.comptabilite);
      store.dispatch('updateSelectedExerciceComptabilite', route.params.exercice);
      store.dispatch('updateAllComptes');
      store.dispatch('updateAllExercicesComptable');
    });
  },
});
</script>

<style scoped>

.title {
  margin: auto;
  text-align: center;
  font-size: 22px;
}

.navButton {
  margin:20px;
}

.navigation {
  text-align: center;
}

.bilanCollapse{
  width:95%;
  margin: auto;
}

.select {
  min-width: 250px;
}

.listButtonToggle {
  margin: 20px;
}

.listButtonExport {
  margin: 20px;
}

.collHeaderContent{
  background-color: var(--va-collapse-header-content-background-color);
  box-shadow: var(--va-collapse-header-content-box-shadow,var(--va-block-box-shadow));
  border-radius: var(--va-collapse-header-content-border-radius,var(--va-block-border-radius));
  height:30px;
  vertical-align: middle;
  display: table-cell;
  vertical-align: middle;
  width:100vw;
}

.collNo{
  margin-right:20px;
  font-weight: bold;
}

.collMontant{
  float: right;
  font-weight: bold;
}

.bilanTable {
  width: 100%;
}

.bilanTDAmount {
  text-align: right !important;
  font-weight: bold;
}

.bilanTRLvl1 {
  font-weight: bold;
  color: var(--va-primary);
}

.bilanData {
  margin-top:20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.filter {
  text-align: center;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.rowBilan {
  min-height: 50px;
}
</style>
