import axios from 'axios';
// eslint-disable-next-line import/no-unresolved, import/extensions
import store from '@/store/index';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Ecriture from '@/types/Ecriture';
// eslint-disable-next-line import/no-unresolved, import/extensions
import EcritureTVA from '@/types/EcritureTVA';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Fichier from '@/types/Fichier';
// eslint-disable-next-line import/no-unresolved, import/extensions
import History from '@/types/History';
// eslint-disable-next-line import/no-unresolved, import/extensions
import User from '@/types/User';

/**
 * Call the API and get an EcritureTVA with the corresponding ID
 * @param id id of the tva ecriture
 * @returns EcritureTVA
 */
// eslint-disable-next-line import/prefer-default-export
export async function getEcritureTVAById(ecritureId:number): Promise<EcritureTVA | undefined> {
  // call http://api.helpcomptabilite.ch/comptabilite/12/exercice/2/ecrituretva/11
  const comptabiliteId = store.getters.selectedComptabiliteId;
  const exerciceId = store.getters.selectedExerciceComptableId;

  return axios.get(
    `/comptabilite/${comptabiliteId}/exercice/${exerciceId}/ecrituretva/${ecritureId}`,
    {
      headers: {
        Authorization: `Bearer ${store.getters.token}`,
      },
    },
  ).then((response) => {
    if (response.status === 200) {
      if (response.data === null) {
        return undefined;
      }
      console.log(response);
      let ecritureResponse = {} as Ecriture;
      if (response.data.tvaSur.fichier === null) {
        ecritureResponse = {
          ID: response.data.tvaSur.id,
          date: new Date(response.data.tvaSur.date),
          debit: store.getters.getCompteById(response.data.tvaSur.debit),
          credit: store.getters.getCompteById(response.data.tvaSur.credit),
          libelle: response.data.tvaSur.libelle,
          montant: response.data.tvaSur.montant,
          createdAt: new Date(response.data.tvaSur.createdAt),
          updatedAt: new Date(response.data.tvaSur.updatedAt),
        } as Ecriture;
      } else {
        ecritureResponse = {
          ID: response.data.tvaSur.id,
          date: new Date(response.data.tvaSur.date),
          debit: store.getters.getCompteById(response.data.tvaSur.debit),
          credit: store.getters.getCompteById(response.data.tvaSur.credit),
          libelle: response.data.tvaSur.libelle,
          montant: response.data.tvaSur.montant,
          createdAt: new Date(response.data.tvaSur.createdAt),
          updatedAt: new Date(response.data.tvaSur.updatedAt),
          fichier: { id: response.data.tvaSur.fichier } as Fichier,
        } as Ecriture;
      }
      const ecriture: EcritureTVA = {
        tauxTVA: Number(response.data.TVA.taux),
        ecriture: ecritureResponse,
        ecritureTVA: {
          ID: response.data.ecriture.id,
          date: new Date(response.data.ecriture.date),
          debit: store.getters.getCompteById(response.data.ecriture.debit),
          credit: store.getters.getCompteById(response.data.ecriture.credit),
          libelle: response.data.ecriture.libelle,
          montant: response.data.ecriture.montant,
          createdAt: new Date(response.data.ecriture.createdAt),
          updatedAt: new Date(response.data.ecriture.updatedAt),
        } as Ecriture,
      } as EcritureTVA;
      return ecriture;
    }
    return undefined;
  })
    .catch((error) => {
      console.log(error);
      /* commit(
        'addMessage',
        {
          message: `Échec de récupération du bilan: ${error}`,
          type: 'danger',
        },
      ); */
      return undefined;
    })
    .finally(() => {
      /* commit('setBackgroundLoading', { backgroundLoading: false }); */
    });
}

/**
 * Call the API and edit an Ecriture based on params
 * @param ecritureId id of the ecriture to edit
 * @param editedEcriture  new ecriturde data
 * @param tva tva amount
 * @param taux tva rate
 * @returns The axios call promise
 */
export async function editEcritureTVAById(
  ecritureId:number,
  editedEcriture: Ecriture,
  tva: number,
  taux: number,
  fichier?: Fichier,
) {
  const timezoneOffset = editedEcriture.date.getTimezoneOffset();
  const correctedDate = new Date(editedEcriture.date.getTime() - 60 * timezoneOffset * 1e3).toISOString().split('T')[0];

  let bodyParameter = {};
  if (fichier !== undefined) {
    bodyParameter = {
      date: correctedDate,
      credit: editedEcriture.credit.id,
      debit: editedEcriture.debit.id,
      libelle: editedEcriture.libelle,
      montant: editedEcriture.montant,
      tva,
      taux,
      fichier: {
        id: fichier.id,
        nom: fichier.nom,
        mime: fichier.mime,
        data: fichier.data,
        type: fichier.type.id,
      },
    };
  } else {
    bodyParameter = {
      date: correctedDate,
      credit: editedEcriture.credit.id,
      debit: editedEcriture.debit.id,
      libelle: editedEcriture.libelle,
      montant: editedEcriture.montant,
      tva,
      taux,
    };
  }
  const configHeader = {
    headers: {
      Authorization: `Bearer ${store.getters.token}`,
    },
  };

  console.log(`API:editEcritureTVAById PUT REQUEST ON  /comptabilite/${store.getters.selectedComptabiliteId}/exercice/${store.getters.selectedExerciceComptableId}/ecrituretva/${ecritureId}`);
  console.log(bodyParameter);

  return axios.put(
    `/comptabilite/${store.getters.selectedComptabiliteId}/exercice/${store.getters.selectedExerciceComptableId}/ecrituretva/${ecritureId}`,
    bodyParameter,
    configHeader,
  ).then((response) => {
    if (response.status === 200) {
      return true;
    }
    return false;
  })
    .catch((error) => false);
}

/**
 * Call the API and get All files that don't have an ecriture
 * @returns Promise Call for Files
 */
export async function getStandaloneFichiers(): Promise <Fichier[] | undefined> {
  const comptabiliteId = store.getters.selectedComptabiliteId;
  const exerciceId = store.getters.selectedExerciceComptableId;

  return axios.get(`/comptabilite/${comptabiliteId}/exercice/${exerciceId}/fichierenattente`, {
    headers: {
      Authorization: `Bearer ${store.getters.token}`,
    },
  }).then((response) => {
    if (response.status === 200) {
      if (response.data === null) {
        return undefined;
      }
      const fichiers: Fichier[] = response.data.map((d:any) => ({
        id: d.id,
        nom: d.nom,
        mime: d.mime,
        data: d.data,
        type: store.getters.getTypesFichierById(d.type),
        createdAt: new Date(d.createdAt),
        createdBy: d.createdBy,
      } as Fichier));
      return fichiers;
    }
    return undefined;
  })
    .catch((error) => {
      console.log(error);
      return undefined;
    });
}

export async function getHistoryEcriture(ecritureId: number): Promise <History | undefined> {
  // call http://api.helpcomptabilite.ch/comptabilite/12/exercice/2/ecrituretva/11
  const comptabiliteId = store.getters.selectedComptabiliteId;
  const exerciceId = store.getters.selectedExerciceComptableId;

  return axios.get(
    `/comptabilite/${comptabiliteId}/exercice/${exerciceId}/history/${ecritureId}`,
    {
      headers: {
        Authorization: `Bearer ${store.getters.token}`,
      },
    },
  ).then((response) => {
    if (response.status === 200) {
      if (response.data === null) {
        return undefined;
      }
      // Here do magic with response
      // get fichier if exist
      let fichier;
      if (response.data.fichier !== null
        && response.data.fichier !== undefined) {
        fichier = {
          id: response.data.fichier.id,
          nom: response.data.fichier.nom,
          mime: response.data.fichier.mime,
          data: response.data.fichier.data,
          type: store.getters.getTypesFichierById(response.data.fichier.type),
          createdAt: new Date(response.data.fichier.createdAt),
          createdBy: response.data.fichier.createdBy,
        } as Fichier;
      }

      // get ecriture TVA if exist
      let tauxTVA;
      let ecritureTVA;
      if (response.data.tva !== null
        && response.data.tva !== undefined) {
        tauxTVA = Number(response.data.tva.taux);
        ecritureTVA = {
          ID: response.data.ecrituretva.id,
          date: new Date(response.data.ecrituretva.date),
          debit: store.getters.getCompteById(response.data.ecrituretva.debit),
          credit: store.getters.getCompteById(response.data.ecrituretva.credit),
          libelle: response.data.ecrituretva.libelle,
          montant: Number(response.data.ecrituretva.montant),
          createdAt: new Date(response.data.ecrituretva.createdAt),
          updatedAt: new Date(response.data.ecrituretva.updatedAt),
          createdBy: { username: response.data.ecrituretva.createdBy } as User,
          updatedBy: { username: response.data.ecrituretva.updatedBy } as User,
        } as Ecriture;
      }

      // get history if exist
      let history;
      if (response.data.history !== null
        && response.data.history !== undefined) {
        history = response.data.history.map((d:any) => ({
          ID: d.id,
          date: new Date(d.date),
          debit: store.getters.getCompteById(d.debit),
          credit: store.getters.getCompteById(d.credit),
          libelle: d.libelle,
          montant: Number(d.montant),
          createdAt: new Date(d.updatedAt),
          updatedAt: new Date(d.updatedAt),
          updatedBy: { username: d.updatedBy } as User,
        } as Ecriture));

        history.sort((a:Ecriture, b:Ecriture) => b.createdAt.getTime() - a.createdAt.getTime());
      }

      // get ecriture
      const ecriture = {
        ID: response.data.ecriture.id,
        date: new Date(response.data.ecriture.date),
        debit: store.getters.getCompteById(response.data.ecriture.debit),
        credit: store.getters.getCompteById(response.data.ecriture.credit),
        libelle: response.data.ecriture.libelle,
        montant: Number(response.data.ecriture.montant),
        createdAt: new Date(response.data.ecriture.createdAt),
        updatedAt: new Date(response.data.ecriture.updatedAt),
        createdBy: { username: response.data.ecriture.createdBy } as User,
        updatedBy: { username: response.data.ecriture.updatedBy } as User,
        fichier,
      } as Ecriture;

      // compile all of them
      const h:History = {
        ecritureTVA,
        ecriture,
        tauxTVA,
        history,
      } as History;
      // return
      return h;
    }
    return undefined;
  })
    .catch((error) => {
      console.log(error);
      /* commit(
        'addMessage',
        {
          message: `Échec de récupération du bilan: ${error}`,
          type: 'danger',
        },
      ); */
      return undefined;
    })
    .finally(() => {
      /* commit('setBackgroundLoading', { backgroundLoading: false }); */
    });
}

/**
 * Call the API to get one file with the ecriture if there is any
 */
export async function getFichierById(fichierId: number): Promise<
{fichier: Fichier, ecriture:Ecriture[]|undefined} | undefined
> {
  const comptabiliteId = store.getters.selectedComptabiliteId;
  const exerciceId = store.getters.selectedExerciceComptableId;

  return axios.get(
    `/comptabilite/${comptabiliteId}/exercice/${exerciceId}/fichier/${fichierId}`,
    {
      headers: {
        Authorization: `Bearer ${store.getters.token}`,
      },
    },
  ).then((response) => {
    if (response.status === 200) {
      if (response.data === null) {
        return undefined;
      }
      console.log(response);

      const fichier = {
        id: response.data.id,
        nom: response.data.nom,
        mime: response.data.mime,
        data: response.data.data,
        type: store.getters.getTypesFichierById(response.data.type),
        createdAt: new Date(response.data.createdAt),
        createdBy: response.data.createdBy,
      } as Fichier;

      if (response.data.ecriture !== undefined) {
        const ecriture = response.data.ecriture.map((d:any) => ({
          ID: d.id,
          date: new Date(d.date),
          debit: store.getters.getCompteById(d.debit),
          credit: store.getters.getCompteById(d.credit),
          libelle: d.libelle,
          montant: d.montant,
          createdAt: new Date(d.createdAt),
          updatedAt: new Date(d.updatedAt),
          fichier: { id: d.id } as Fichier,
          createdBy: { username: d.createdBy } as User,
          updatedBy: { username: d.updatedBy } as User,
        } as Ecriture));
        console.log(ecriture);
        return {
          ecriture,
          fichier,
        };
      }

      return {
        ecriture: undefined,
        fichier,
      };
    }
    return undefined;
  })
    .catch((error) => {
      console.log(error);
      /* commit(
        'addMessage',
        {
          message: `Échec de récupération du bilan: ${error}`,
          type: 'danger',
        },
      ); */
      return undefined;
    })
    .finally(() => {
      /* commit('setBackgroundLoading', { backgroundLoading: false }); */
    });
}
