<template>
  <VaSidebar
    hoverable
    position="left"
    minimizedWidth="64px"
    style="position: absolute;"
    v-if="isLogged">
    <VaSidebarItem active to="/">
      <VaSidebarItemContent>
        <VaIcon name="home" />
        <VaSidebarItemTitle> Accueil</VaSidebarItemTitle>
      </VaSidebarItemContent>
    </VaSidebarItem>
    <!-- Todo Comptabilite Accordion -->
    <!-- Inside menu comptabilite -->
    <!-- inside comptabilite access to journal et bilan -->
    <VaSidebarItem
      v-for="comptabilite in comptabilites"
      :key="comptabilite.id"
      :to="{ name: 'comptabilite', params: { comptabilite: comptabilite.id } }"
      active-color="warning"
      :active="isComptabiliteSelected(comptabilite.id)">
      <VaSidebarItemContent>
        <VaIcon name="business" />
        <VaSidebarItemTitle>{{ comptabilite.nom }}</VaSidebarItemTitle>
      </VaSidebarItemContent>
    </VaSidebarItem>
    <VaSpacer />
    <VaCollapse>
      <template #header="{ value: isCollapsed }">
        <VaSidebarItem>
          <VaSidebarItemContent>
            <VaIcon name="open_in_new" />
            <VaSidebarItemTitle>Actions</VaSidebarItemTitle>
            <VaSpacer />
            <VaIcon :name="isCollapsed ? 'va-arrow-up' : 'va-arrow-down'" />
          </VaSidebarItemContent>
        </VaSidebarItem>
      </template>

      <template #body>
        <VaSidebarItem
          to="/calculateur">
          <VaSidebarItemContent>
            <VaIcon name="calculate" />
            <VaSidebarItemTitle>Calculateur salaire</VaSidebarItemTitle>
          </VaSidebarItemContent>
        </VaSidebarItem>

        <VaSidebarItem>
          <VaSidebarItemContent>
            <VaIcon name="drafts" />
            <VaSidebarItemTitle>Administration</VaSidebarItemTitle>
          </VaSidebarItemContent>
        </VaSidebarItem>
      </template>
    </VaCollapse>
  </VaSidebar>
  <div class="content">
    <div v-if="isLogged" class="right">
      <router-view />
    </div>
    <div v-else class="center">
      <router-view />
    </div>
  </div>
  <div class="appVersion">
    {{ appVersion }}
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
  },
  computed: {
    isLogged() {
      console.log(`User is logged: ${this.$store.getters.logged}`);
      return this.$store.getters.logged;
    },
    comptabilites() {
      return this.$store.getters.comptabilites;
    },
    selectedComptabilite() {
      return this.$store.getters.selectedComptabiliteId;
    },
    toastMessage() {
      return this.$store.getters.message;
    },
    appVersion() {
      return this.$store.getters.appVersion;
    },
  },
  watch: {
    toastMessage: {
      handler(newMessage) {
        if (newMessage !== null) {
          this.$vaToast.init(({
            message: newMessage.message,
            color: newMessage.type,
            duration: 1500,
          }));
        }
      },
    },
  },
  methods: {
    isComptabiliteSelected(comptabiliteid) {
      return this.$store.getters.selectedComptabiliteId === comptabiliteid;
    },
  },
});
</script>

<style lang="scss">
:root {
  --titleHeight: 56px;
}
body {
  margin: 0;
}

.title{
  background-color: var(--va-primary);
  color:white;
  height: var(--titleHeight);
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

#app {
  height: calc(100vh - 3px);
  position: relative;
}

.va-sidebar {
  .va-sidebar__title {
    transition: opacity 0.2s ease-in-out;
  }

  &--minimized {
    .va-sidebar__title {
      opacity: 0;
    }
  }
}

.va-sidebar__item__content {
  min-height: 0px;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.right {
  margin: 0;
  position: absolute;
  top: 0px;
  left: 64px;
  width: calc(100% - 64px);
}

.content {
  height: 100%;
  width: calc(100% - 64px);
  overflow: auto;
}

.appVersion {
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 1;
  font-style: italic;
  font-size: small;
}
</style>
